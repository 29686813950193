import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { TableHeadCell } from '../table/enhanced-table-head';
import Table from '../table/table';
import { IDuplicateGames } from '../../models/models';
import { asDateTime, asLink } from '../../utils/utils';
import CopyToClipboard from '../copy-to-clipboard/copy-to-clipboard';

import './duplicate-games.scss';

interface DuplicateGameCell extends TableHeadCell {
    key: keyof IDuplicateGames | 'actions' | '_id' | 'usebio' | 'result_link' | 'playlink';
}

interface IDuplicateGamesProps {
    duplicateGames?: IDuplicateGames[];
    title?: string;
}

export const DuplicateGames: FunctionComponent<IDuplicateGamesProps> = props => {
    const { t } = useTranslation();
    const { duplicateGames, title } = props;

    const headCells: DuplicateGameCell[] = [
        { key: 'date_added', label: t(`duplicateGame.date_added`), options: { format: asDateTime() } },
        { key: 'eventname', label: t(`duplicateGame.eventname`), options: {} },
        { key: 'playlink', label: 'Copy Play Link' },
        { key: '_id', label: t(`duplicateGame.uuid_col`), options: {} },
        { key: 'usebio', label: t(`duplicateGame.usebio`), options: { format: asLink(t(`duplicateGame.usebio`)) } },
        { key: 'result_link', label: t(`duplicateGame.result_link`), options: { format: asLink(t(`duplicateGame.toGame`)) } },
        { key: 'actions', label: 'Copy Result Link' }
    ];

    const rows = (duplicateGames || []).map(duplicateGame => ({
        ...duplicateGame,
        result_link: `https://onlinebridge.club/?gameResults=${duplicateGame.uuid}`,
        usebio: `https://api.onlinebridge.club/usebiogame.php?uuid=${duplicateGame.uuid}`,
        actions: [<CopyToClipboard value={`https://onlinebridge.club/?gameResults=${duplicateGame.uuid}`} />],
        playlink: [
            <CopyToClipboard value={`https://api.onlinebridge.club/obc/private/${duplicateGame.playspace}?tuid=${duplicateGame.uuid}`} />
        ],
        _id: `${duplicateGame.uuid}`
    }));

    return (
        <section className={`DuplicateGames`}>
            {duplicateGames && (
                <Table
                    title={title || t(`duplicateGames.title`)}
                    noDataMessage={t(`duplicateGames.noDataMessage`)}
                    headCells={headCells}
                    rows={rows}
                />
            )}
        </section>
    );
};
