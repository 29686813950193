import React, { FunctionComponent, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Button, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { Orders } from '../../shared/orders/orders';

import './customer-dashboard.scss';
import { ICustomerDashboard } from '../../models/customer-models';
import { IClassRoomHand, IClassRoomHandTeacher } from '../../models/models';
import DateTime from '../../shared/date-time/date-time';
import useAxios from 'axios-hooks';

interface ICustomerDashboardProps {
    data: ICustomerDashboard;
}

export const CustomerDashboard: FunctionComponent<ICustomerDashboardProps> = props => {
    const { data } = props;

    const teachers: IClassRoomHandTeacher[] = (data.classroom_hands ?? []).reduce<IClassRoomHandTeacher[]>((teachers, classRoomHand) => {
        const { teacher_id, teacher_first_name, teacher_last_name, teacher_url } = classRoomHand;
        if (teachers.find(teacher => teacher.teacher_id === classRoomHand.teacher_id)) {
            return teachers;
        }
        return [...teachers, { teacher_id, teacher_first_name, teacher_last_name, teacher_url }];
    }, []);

    const stopPropagation = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
    };

    return data ? (
        <section className={`CustomerDashboard`}>
            <div className="class-room-hands">
                <Typography variant="h6" className="class-room-hands-title">
                    My Classroom Hands
                </Typography>
                {teachers.map(teacher => (
                    <Accordion key={teacher.teacher_id}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className="teacher"
                        >
                            {teacher.teacher_first_name} {teacher.teacher_last_name}
                            {teacher.teacher_url && (
                                <div className="register">
                                    :{' '}
                                    <a href={teacher.teacher_url} target="_blank" onClick={stopPropagation}>
                                        click here
                                    </a>{' '}
                                    to register for upcoming bridge classes
                                </div>
                            )}
                        </AccordionSummary>
                        <AccordionDetails className="class-room-hand">
                            {data.classroom_hands
                                .filter(classRoomHand => classRoomHand.teacher_id === teacher.teacher_id)
                                .map(classRoomHand => (
                                    <ClassRoomHandComponent classRoomHand={classRoomHand} />
                                ))}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
            <Orders customerOrders={data.orders} />
        </section>
    ) : null;
};

const ClassRoomHandComponent: FunctionComponent<{ classRoomHand: IClassRoomHand }> = ({
    classRoomHand: { file_date, file_name, deal_set_id }
}) => {
    const [{ loading, data, error }, fetchUrl] = useAxios(
        {
            url: `getplaylink.php?dealset=${deal_set_id}`,
            method: 'POST'
        },
        { manual: true }
    );

    useEffect(() => {
        if (data && !loading) {
            openPlay();
        }
    }, [loading, data]);

    const openPlay = () => {
        //console.log("Link request" , data);
        window.location = data;
    };

    return (
        <>
            <Button variant="contained" color="primary" className="refresh" onClick={() => fetchUrl()} disabled={loading}>
                Play
            </Button>
            <div>{file_name}</div>
            <div>
                <DateTime date={file_date} noTime={true} />
            </div>
        </>
    );
};

export default CustomerDashboard;
